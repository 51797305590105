import React from 'react'
import Innovations from '../components/innovations'
import InnovationJSON from "../content/innovations.json"

export default function rise() {
    const content = InnovationJSON.innovations[2]
    return (
        <Innovations content={content} />
    )
}
